import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container } from 'react-bootstrap'
import Header from "./header"
import "../components/layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const site = {
    display: "flex",
    minHeight: "100vh",
    flexDirection: "column",
  }

  const siteContent = {
    flexGrow: 1,
    marginTop: "100px",
  }

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div style={site}>
        <main style={siteContent}>{children}</main>
        <footer id="footer">
          <Container fluid>
            © {new Date().getFullYear()} {data.site.siteMetadata.title}
            {` `}
            <br/>
            <Link to="/datenschutz/">Datenschutz</Link> &amp; <Link to="/impressum/">Impressum</Link>
          </Container>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
